import * as React from 'react'
import classNames from 'classnames'

import SiteHeader from '@components/SiteHeader'
import SiteFooter from '@components/SiteFooter'

const MainLayout = ({children}) => {
  const [isActive, setIsActive] = React.useState(false)

  React.useEffect(() => {
    // Not to activate transition right away to prevent
    // first-load premature transition.
    setIsActive(true)
  }, [])

  return (
    <div
      className={classNames('Layout', {
        'Layout--active': isActive,
      })}
    >
      <SiteHeader />

      {children}

      <SiteFooter />
    </div>
  )
}

export default MainLayout
