import React from 'react'
import Link from 'next/link'
import Image from 'next/image'
import moment from 'moment'
import {logEvent} from '@lib/gtm'

import {t} from '@lib/helpers'
import PropTypes from 'prop-types'

import FacebookIcon from '@components/icons/Facebook'
import LinkedInIcon from '@components/icons/LinkedIn'
import YoutubeIcon from '@components/icons/Youtube'
import TikTokIcon from '@components/icons/TikTok'

import ISOCert1 from '../public/images/iso-cert-1.svg'
import ISOCert2 from '../public/images/iso-cert-2.svg'

const ADDRESS =
  '1778 อาคารซัมเมอร์ฮับ ออฟฟิศ, ชั้น 6<br/> ถนนสุขุมวิท แขวงพระโขนง เขตคลองเตย <br/> กรุงเทพมหานคร 10110 <br/> ประเทศไทย'
const COPYRIGHT = 'Data Wow Co., Ltd. All Rights Reserved.'
const PRIVACY = 'นโยบายความเป็นส่วนตัว'
const SECURITY = 'ความปลอดภัย'
const TELEPHONE = '02-024-5560'
const TELEPHONE_CALL = '020245560'

const FooterNavLinks = [
  // {
  //   label: t('layout.Footer.links.nav.0'),
  //   href: '/',
  // },
  {
    label: 'ร่วมงานกับเรา',
    href: '/careers',
  },
  {
    label: 'บทความ',
    href: '/blogs',
  },
  {
    label: 'ติดต่อเรา',
    href: '/contact',
  },
]

const FooterBodyLinks = {
  services: [
    {
      label: 'AI Consultant',
      href: '/ai-consultation',
    },
    {
      label: 'Custom AI Development',
      href: '/custom-ai',
    },
    {
      label: 'AI Readiness',
      href: '/ai-readiness',
    },
    {
      label: 'Data Analytics',
      href: '/services/data-analytics',
    },
    {
      label: 'Data Labeling',
      href: '/services/data-labeling',
    },
    {
      label: 'PDPA Core',
      href: '/products/pdpa-core',
    },
    {
      label: 'Corporate Training',
      href: 'https://wowlearn.datawow.io/',
    },
    {
      label: 'E-Learning',
      href: 'https://wowlearn.datawow.co.th/accelerate-your-data-analytics-with-chatgpt/',
    },
  ],
  products: [
    {
      label: 'PDPA Suite',
      href: 'https://pdpa.pro/our-products',
    },
    {
      label: 'Accurately',
      href: '/products/accurately',
    },
  ],
  industries: [
    {
      label: '<span>การผลิต</span><span>และ</span><span>โลจิสติกส์</span>',
      href: '/products/logistics',
    },
    {
      label: 'เครื่องจักร',
      href: '/ai-consultation/machinery',
    },
    {
      label: 'ยานยนต์',
      href: '/ai-consultation/automotive',
    },
    {
      label: 'สิ่งทอ',
      href: '/ai-consultation/textile',
    },
    {
      label: '<span>อาหาร</span><span>และ</span><span>เครื่องดื่ม</span>',
      href: '/ai-consultation/food',
    },
    {
      label: 'เกษตรกรรม',
      href: '/ai-consultation/agriculture',
    },
    // {
    //   label: '<span>โลจิสติกส์</span><span>ที่ใช้</span><span>ความเย็น</span>',
    //   href: '/products/logistics',
    // },
    // {
    //   label: 'วัสดุก่อสร้าง',
    //   href: '/products/logistics',
    // },
    // {
    //   label: 'พลังงาน',
    //   href: '/products/logistics',
    // },
  ],
  company: [
    {
      label: 'ร่วมงานกับเรา',
      href: '/careers',
    },
    {
      label: 'บทความ',
      href: '/blogs',
    },
    {
      label: 'ติดต่อเรา',
      href: '/contact',
    },
  ],
  // demo: [
  //   {
  //     label: 'OCR',
  //     href: '/demo/ocr',
  //   },
  //   {
  //     label: '<span>ตรวจจับ</span><span>ใบหน้า</span><span>ด้วย AI</span>',
  //     href: '/demo/demographic',
  //   },
  //   {
  //     label: '<span>ตรวจจับวัตถุ</span><span>และ</span><span>จำแนกภาพ</span><span>โดยใช้ AI</span>',
  //     href: '/demo/detection',
  //   },
  // ],
}

const Columns = [
  'ที่ตั้งบริษัท',
  'บริการของเรา',
  'ผลิตภัณฑ์ของเรา',
  'อุตสาหกรรม',
  'เกี่ยวกับเรา',
]

const SiteFooter = (props) => {
  const {className = ''} = props
  return (
    <div className={`FooterV2 ${className}`}>
      <div className='container'>
        <div className='FooterV2__body'>
          {Object.keys(FooterBodyLinks).map((bodyLink, bodyIndex) => (
            <div className='FooterV2__body__container' key={bodyIndex}>
              <h5 className='FooterV2__body__title'>
                {Columns[bodyIndex + 1]}
              </h5>
              <ul>
                {FooterBodyLinks[bodyLink].map((x, i) => (
                  <li key={x + i} className='FooterV2__body__listitem'>
                    <Link href={x.href}>
                      <a
                        className='FooterV2__links'
                        dangerouslySetInnerHTML={{__html: x.label}}
                        onClick={() => {
                          logEvent({
                            ga: {
                              category: 'Homepage',
                              action: 'Click ${x.label}',
                              label: 'Footer',
                            },
                          })
                        }}
                      ></a>
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          ))}
          <div className='FooterV2__body__logo'>
            <Link href='/'>
              <a
                className='Nav__logo-brand'
                title='datawow.co.th'
                onClick={() => {
                  logEvent({
                    ga: {
                      category: 'Homepage',
                      action: 'Click Homepage',
                    },
                  })
                }}
              >
                <img
                  src='/images/logo-white.svg'
                  alt='datawow logo'
                  width='260px'
                  height='40px'
                  className='FooterV2__body__image'
                />
              </a>
            </Link>
          </div>
          <div className='FooterV2__body__container FooterV2__body__office-container'>
            <h5 className='FooterV2__body__title'>{Columns[0]}</h5>
            <p
              className='FooterV2__body__address'
              dangerouslySetInnerHTML={{__html: ADDRESS}}
            ></p>
            <div className='FooterV2__body__contact'>
              <Image
                src='/images/footer-new__phone.svg'
                alt='phone'
                width='16px'
                height='16px'
              />
              <a
                className='FooterV2__links'
                href={`tel:${TELEPHONE_CALL}`}
                onClick={() => {
                  logEvent({
                    ga: {
                      category: 'Homepage',
                      action: 'Click Phone',
                      label: 'Footer',
                    },
                  })
                }}
              >
                {TELEPHONE}
              </a>
            </div>
            <div className='FooterV2__body__contact'>
              <Image
                src='/images/footer-new__mail.svg'
                alt='phone'
                width='16px'
                height='16px'
              />
              <a
                href={`mailto:${t('layout.Footer.tl.email')}`}
                className='FooterV2__links'
                onClick={() => {
                  logEvent({
                    ga: {
                      category: 'Homepage',
                      action: 'Click Email',
                      label: 'Footer',
                    },
                  })
                }}
              >
                {t('layout.Footer.tl.email')}
              </a>
            </div>
            <div className='FooterV2__socials'>
              <h5 className='FooterV2__socials__title'>ติดตามเรา</h5>
              <div className='FooterV2__socials__links'>
                <a
                  className='FooterV2__links'
                  href='https://www.facebook.com/datawowai/'
                  target='_blank'
                  rel='nofollow noopener noreferrer'
                  onClick={() => {
                    logEvent({
                      ga: {
                        category: 'Homepage',
                        action: 'Click Facebook',
                        label: 'Footer',
                      },
                    })
                  }}
                >
                  <FacebookIcon />
                </a>
                <a
                  className='FooterV2__links'
                  href='https://www.linkedin.com/company/datawowio/'
                  target='_blank'
                  rel='nofollow noopener noreferrer'
                  onClick={() => {
                    logEvent({
                      ga: {
                        category: 'Homepage',
                        action: 'Click LinkedIn',
                        label: 'Footer',
                      },
                    })
                  }}
                >
                  <LinkedInIcon />
                </a>
                <a
                  className='FooterV2__links'
                  href='https://www.youtube.com/@datawowio/'
                  target='_blank'
                  rel='nofollow noopener noreferrer'
                  onClick={() => {
                    logEvent({
                      ga: {
                        category: 'Homepage',
                        action: 'Click Youtube',
                        label: 'Footer',
                      },
                    })
                  }}
                >
                  <YoutubeIcon />
                </a>
                <a
                  className='FooterV2__links'
                  href='https://www.tiktok.com/@datawow/'
                  target='_blank'
                  rel='nofollow noopener noreferrer'
                  onClick={() => {
                    logEvent({
                      ga: {
                        category: 'Homepage',
                        action: 'Click TikTok',
                        label: 'Footer',
                      },
                    })
                  }}
                >
                  <TikTokIcon />
                </a>
              </div>
            </div>
          </div>
          <div className='FooterV2__body__container FooterV2__body__iso-container'>
            <Image src={ISOCert1} alt='ISO ISM' />
            <Image src={ISOCert2} alt='ISO PIM' />
          </div>
        </div>

        <div className='copyright'>
          <div className='copyright__left'>
            <p>
              &copy; {moment().year()} {COPYRIGHT}
            </p>
          </div>
          <div className='copyright__right'>
            <Link
              href='/policy'
              target='_blank'
              rel='nofollow noopener noreferrer'
            >
              <a
                className='copyright__links'
                dangerouslySetInnerHTML={{
                  __html: PRIVACY,
                }}
                onClick={() => {
                  logEvent({
                    ga: {
                      category: 'Homepage',
                      action: 'Click Privacy Policy',
                      label: 'Footer',
                    },
                  })
                }}
              ></a>
            </Link>
            <Link
              href='/security'
              target='_blank'
              rel='nofollow noopener noreferrer'
            >
              <a
                className='copyright__links'
                dangerouslySetInnerHTML={{
                  __html: SECURITY,
                }}
                onClick={() => {
                  logEvent({
                    ga: {
                      category: 'Homepage',
                      action: 'Click Security',
                      label: 'Footer',
                    },
                  })
                }}
              ></a>
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

SiteFooter.propTypes = {
  className: PropTypes.string,
}

export default SiteFooter
